.hero {
  background-image: url("");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #0c0c0c;
}
/* .primary-btn a{
 z-index: 1;
} */
.hero #heading h3 {
  color: #000000;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.hero.primary-btn {
  margin-top: -69px;
    border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 10vh;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }
  .hero p{
    display: none;
  }
  #heading h1 {
    font-size: 25px;
}
.hero #heading h3 {
  margin-top: 67px;
}
.primary-btn {
  display: none;
}
}
